import LandingHeroWrapper from "@components/LandingHeroWrapper/LandingHeroWrapper";
import TitleSection from "./components/TitleSection/TitleSection";
import WhatsAppImage from "./components/WhatsAppImage/WhatsAppImage";

const Hero = () => {
    return (
        <LandingHeroWrapper>
            <TitleSection />
            <WhatsAppImage />
        </LandingHeroWrapper>
    );
};

export default Hero;
