import { Button, Center, HStack, Icon, Link, Show } from "@chakra-ui/react";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { Link as RouterLink } from "react-router-dom";

const navbarItems = [
    {
        label: "¿Cómo funciona Tikzet?",
        href: import.meta.env.VITE_DOCS_URL,
        mobileIcon: IoIosHelpCircleOutline,
    },
    {
        label: "Precios",
        href: "/prices",
    },
];

const NavbarItems = () => {
    return (
        <HStack spacing={{ base: 3, md: 8 }} alignItems={"center"}>
            {navbarItems.map((item) => (
                <Link
                    as={RouterLink}
                    to={item.href}
                    color="white"
                    key={item.href}
                    _hover={{
                        textDecoration: "none",
                        textShadow: "0 0 10px #fff",
                    }}
                >
                    <Show above="md">{item.label}</Show>
                    <Show below="md">
                        {item.mobileIcon ? (
                            <Center h="full">
                                <Icon
                                    color="primary"
                                    as={item.mobileIcon}
                                    fontSize={"3xl"}
                                />
                            </Center>
                        ) : (
                            <Button variant={"secondary"} size={"xs"}>
                                {item.label}
                            </Button>
                        )}
                    </Show>
                </Link>
            ))}
        </HStack>
    );
};

export default NavbarItems;
