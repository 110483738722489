import { apiClient } from "@api/config";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export interface UseGetPremiumCreditsPriceQueryResponse {
    unitPrice: number;
    image: string;
    bundles: UseGetPremiumCreditsPriceQueryResponseBundle[];
}

export interface UseGetPremiumCreditsPriceQueryResponseBundle {
    unitPrice: number;
    start: number;
    end: number | null;
    image: string;
}

export const useGetPremiumCreditsPriceQuery = (
    options?: Omit<
        UseQueryOptions<UseGetPremiumCreditsPriceQueryResponse>,
        "queryKey"
    >
) =>
    useQuery(
        [useGetPremiumCreditsPriceQuery.name],
        async () => {
            const { data } =
                await apiClient.get<UseGetPremiumCreditsPriceQueryResponse>(
                    "/premiumCredits/price"
                );
            return data;
        },
        options
    );
