import { VStack } from "@chakra-ui/react";
import BoxedPresentationItem from "@components/BoxedPresentationItem/BoxedPresentationItem";
import LandingTitle from "@components/LandingTitle/LandingTitle";
import EventPage from "./assets/event_page.webp";

import SalesReport from "./assets/sales_report.webp";
import ScanQr from "./assets/scan_qr.webp";

const UseCases = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <LandingTitle title="¿QUE PUEDO HACER CON EL SISTEMA DE VENTAS?" />
            <BoxedPresentationItem
                title="Crea una página web de tu evento para vender con envío automático de QRs"
                description="Incluye una página web de tu evento y un sistema de referidos para que tengas vendedores. Además, podes vender consumos y mesas."
                subtitle="PAGINA WEB"
                image={EventPage}
            />
            <BoxedPresentationItem
                title="Validación de QRs desde tu cuenta de Tikzet"
                description="Vas a poder validar los QRs desde tu cuenta de Tikzet. Podes hacerlo mediante cualquier dispositivo con acceso a internet y no tiene ningún costo extra."
                subtitle="VALIDACION DE QRS"
                image={ScanQr}
            />
            <BoxedPresentationItem
                title="Acceso a reportes en tiempo real"
                description="Accedé a un informe detallado de tus ventas con filtros e información de tus clientes."
                subtitle="REPORTES"
                image={SalesReport}
            />
        </VStack>
    );
};

export default UseCases;
