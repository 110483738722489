import { Image } from "@chakra-ui/react";
import PhoneWithEvent from "./assets/phone_with_event.webp";

const EventImage = () => {
    return (
        <Image
            src={PhoneWithEvent}
            h={{ base: "auto", md: "auto" }}
            w={{ base: "full", md: "auto" }}
            maxH={{ base: "auto", md: "35rem" }}
            maxW="100%"
            overflow="hidden"
        />
    );
};

export default EventImage;
