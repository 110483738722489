import { useGetPremiumCreditsPriceQuery } from "@api/useCases/premiumCredits/queries/getPremiumCreditsPrice/useGetPremiumCreditsPriceQuery";
import { VStack } from "@chakra-ui/react";
import LandingTitle from "@components/LandingTitle/LandingTitle";
import { useMemo } from "react";
import MercadoPago from "./components/MercadoPago/MercadoPago";
import QrPrice from "./components/QrPrice/QrPrice";

interface Props {
    onlineSale: boolean;
}

const PriceSection = ({ onlineSale }: Props) => {
    const { data: prices, isSuccess } = useGetPremiumCreditsPriceQuery();
    const cheapestPrice = useMemo(
        () =>
            prices?.bundles.sort((a, b) => a.unitPrice - b.unitPrice)[0]
                .unitPrice,
        [prices]
    );

    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <LandingTitle title={onlineSale ? "PRECIO Y COBROS" : "PRECIO"} />
            {isSuccess && (
                <QrPrice
                    cheapestPrice={cheapestPrice!}
                    normalPrice={prices.unitPrice}
                />
            )}
            {onlineSale && <MercadoPago />}
        </VStack>
    );
};

export default PriceSection;
