import { Box, VStack } from "@chakra-ui/react";
import LandingTitle from "@components/LandingTitle/LandingTitle";
import TestimoniesCarousel from "./components/TestimoniesCarousel/TestimoniesCarousel";

const ClientTestimoniesSection = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <LandingTitle title="TESTIMONIOS DE CLIENTES" />
            <Box
                w="full"
                ms={{ base: -5, md: "-15vw", lg: "-20vw", "2xl": "-25vw" }}
            >
                <TestimoniesCarousel />
            </Box>
        </VStack>
    );
};

export default ClientTestimoniesSection;
