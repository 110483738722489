import {
    Button,
    Flex,
    Heading,
    HStack,
    Image,
    Link,
    Text,
    VStack,
} from "@chakra-ui/react";

type Props = {
    image: string;
    title: string;
    description: string;
    subtitle: string;
    cta?: {
        label: string;
        href: string;
    };
};

const BoxedPresentationItem = ({
    image,
    title,
    description,
    subtitle,
    cta,
}: Props) => {
    return (
        <HStack alignItems="flex-start" spacing={[0, 3]} w="full">
            <Text
                fontFamily="landingBody"
                transform={"auto"}
                rotate={-180}
                css={{
                    writingMode: "vertical-rl",
                }}
                color="gray.400"
                w={["10%", "auto"]}
            >
                {subtitle}
            </Text>
            <Flex
                w="full"
                flexDir={["column", "row"]}
                alignItems="flex-start"
                gap={[8, 5]}
            >
                <Image src={image} alt={title} w={["full", "250px"]} />
                <VStack
                    p={[0, 4]}
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={4}
                    flex={1}
                    mt="auto"
                >
                    <Heading fontWeight="500" fontSize={["2xl", "3xl"]}>
                        {title}
                    </Heading>
                    <Text color="gray.300" fontSize={["sm", "md"]}>
                        {description}
                    </Text>
                    {cta && (
                        <Button
                            size={"sm"}
                            as={Link}
                            href={cta.href}
                            variant="primary"
                        >
                            {cta.label}
                        </Button>
                    )}
                </VStack>
            </Flex>
        </HStack>
    );
};

export default BoxedPresentationItem;
