import LandingHeroWrapper from "@components/LandingHeroWrapper/LandingHeroWrapper";
import QrImage from "./components/QrImage/QrImage";
import TitleSection from "./components/TitleSection/TitleSection";

const Hero = () => {
    return (
        <LandingHeroWrapper>
            <TitleSection />
            <QrImage />
        </LandingHeroWrapper>
    );
};

export default Hero;
