import { VStack } from "@chakra-ui/react";
import BoxedPresentationItem from "@components/BoxedPresentationItem/BoxedPresentationItem";
import LandingTitle from "@components/LandingTitle/LandingTitle";

import QrInPhone from "./assets/qr_in_phone.webp";
import SalesReport from "./assets/sales_report.webp";
import ScanQr from "./assets/scan_qr.webp";

const UseCases = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <LandingTitle title="¿QUE PUEDO HACER CON EL SISTEMA DE INGRESOS?" />
            <BoxedPresentationItem
                title="Envío de entradas personalizadas cargando los datos de tu cliente"
                description="Podes asociarlas a un nombre, producto y agregarle un comentario personalizado."
                subtitle="CONTROL DE INGRESOS"
                image={QrInPhone}
            />
            <BoxedPresentationItem
                title="Validación de QRs desde tu cuenta de Tikzet"
                description="Vas a poder validar los QRs desde tu cuenta de Tikzet. Podes hacerlo mediante cualquier dispositivo con acceso a internet y no tiene ningún costo extra."
                subtitle="VALIDACION DE QRS"
                image={ScanQr}
            />
            <BoxedPresentationItem
                title="Acceso a reportes en tiempo real"
                description="Accedé a un informe detallado de asistencia con filtros e información de cada QR."
                subtitle="REPORTES"
                image={SalesReport}
            />
        </VStack>
    );
};

export default UseCases;
