import { keyframes, Text, TextProps } from "@chakra-ui/react";

const blink = keyframes`
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
`;

interface Props extends TextProps {
    title: string;
}

const LandingTitle = ({ title, fontSize, ...rest }: Props) => {
    return (
        <Text
            fontFamily="landingBody"
            color="gray.300"
            fontSize={fontSize || { base: "lg", md: "2xl" }}
            {...rest}
        >
            {title}
            <Text
                color="inherit"
                as="span"
                animation={`${blink} .75s step-end infinite`}
            >
                _
            </Text>
        </Text>
    );
};

export default LandingTitle;
