import FacebookIcon from "@assets/icons/facebook_logo.svg?react";
import SocialMediaButton from "./SocialMediaButton";

const facebookData = {
    clientId: import.meta.env.VITE_FACEBOOK_CLIENT_ID,
    responseType: "token",
    scope: "email, public_profile",
    redirectUri: `${window.location.origin}/auth/redirect/facebook`,
};

const facebookRedirectUrl =
    "https://www.facebook.com/v15.0/dialog/oauth" +
    `?client_id=${facebookData.clientId}` +
    `&response_type=${facebookData.responseType}` +
    `&scope=${facebookData.scope}` +
    `&redirect_uri=${facebookData.redirectUri}`;

const FacebookSignIn = () => {
    return (
        <SocialMediaButton
            icon={FacebookIcon}
            label="Continuar con Facebook"
            to={facebookRedirectUrl}
        />
    );
};
export default FacebookSignIn;
