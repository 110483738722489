import { Flex, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";

type Props = {
    image: string;
    quantity: number;
    title: string;
    description: string;
    price: number;
};

const PacksItem = ({ image, title, description, price, quantity }: Props) => {
    return (
        <HStack alignItems="flex-start" spacing={[0, 3]} w="full">
            <Text
                fontFamily="landingBody"
                transform={"auto"}
                rotate={-180}
                css={{
                    writingMode: "vertical-rl",
                }}
                color="gray.400"
                w={["10%", "auto"]}
            >
                {quantity} CREDITOS
            </Text>
            <Flex
                w="full"
                flexDir={["column", "row"]}
                alignItems="flex-start"
                gap={[8, 5]}
            >
                <Image src={image} alt={title} w={["full", "250px"]} />
                <VStack
                    px={[0, 4]}
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={1}
                    flex={1}
                    mt="auto"
                >
                    <Heading fontWeight="500" fontSize={["2xl", "3xl"]}>
                        {title}
                    </Heading>
                    <Text color="gray.300" fontSize={["sm", "md"]} mt={3}>
                        {description}
                    </Text>
                    <Text color="primary" fontSize={"2xl"} fontWeight="500">
                        ARS$ {price}
                        <Text as="span" color="inherit" fontWeight="normal">
                            /crédito
                        </Text>
                    </Text>
                </VStack>
            </Flex>
        </HStack>
    );
};

export default PacksItem;
