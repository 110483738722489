import { Box, VStack } from "@chakra-ui/react";
import ClientTestimoniesSection from "@components/ClientTestimoniesSection/ClientTestimoniesSection";
import FaqsSection from "@components/FaqsSection/FaqsSection";
import Footer from "@components/Footer/Footer";
import FreeCreditsSection from "@components/FreeCreditsSection/FreeCreditsSection";
import HowToSection from "@components/HowToSection/HowToSection";
import PriceSection from "@components/PriceSection/PriceSection";
import RecentEvents from "@components/RecentEvents/RecentEvents";
import { useNavbarHeight } from "@hooks/useNavbarHeight";
import useRedirectToAppIfAuthenticated from "@hooks/useRedirectToAppIfAuthenticated";
import Hero from "./components/Hero/Hero";
import UseCases from "./components/UseCases/UseCases";

const WithoutOnlineSalesPage = () => {
    const navbarHeight = useNavbarHeight();

    useRedirectToAppIfAuthenticated();

    return (
        <VStack
            w="full"
            spacing="8rem"
            pt={{ base: navbarHeight, md: 0 }}
            px={{ base: 5, md: "15vw", lg: "20vw", "2xl": "25vw" }}
        >
            <Hero />
            <RecentEvents />
            <UseCases />
            <PriceSection onlineSale={false} />
            <HowToSection />
            <ClientTestimoniesSection />
            <FaqsSection />
            <FreeCreditsSection />
            <Box w="100vw">
                <Footer />
            </Box>
        </VStack>
    );
};

export default WithoutOnlineSalesPage;
