import LandingHeroWrapper from "@components/LandingHeroWrapper/LandingHeroWrapper";
import CreditsImage from "./components/CreditsImage/CreditsImage";
import TitleSection from "./components/TitleSection/TitleSection";

interface Props {
    cheapestPrice: number;
    normalPrice: number;
}

const Hero = ({ cheapestPrice, normalPrice }: Props) => {
    return (
        <LandingHeroWrapper>
            <TitleSection
                cheapestPrice={cheapestPrice}
                normalPrice={normalPrice}
            />
            <CreditsImage />
        </LandingHeroWrapper>
    );
};

export default Hero;
