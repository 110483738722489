import { Flex, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import HandWithCredits from "./assets/hand_with_credits.webp";

interface Props {
    cheapestPrice: number;
    normalPrice: number;
}

const QrPrice = ({ cheapestPrice, normalPrice }: Props) => {
    return (
        <HStack alignItems="flex-start" spacing={[0, 3]} w="full">
            <Text
                fontFamily="landingBody"
                transform={"auto"}
                rotate={-180}
                css={{
                    writingMode: "vertical-rl",
                }}
                color="gray.400"
                w={["10%", "auto"]}
            >
                MEJOR PRECIO DEL MERCADO
            </Text>
            <Flex
                w="full"
                flexDir={["column", "row"]}
                alignItems="flex-start"
                gap={[8, 3]}
            >
                <VStack alignItems="flex-start" spacing={3}>
                    <Heading
                        fontFamily="landingHeading"
                        fontSize={["35px", "3xl"]}
                        fontWeight="500"
                        lineHeight=".9"
                    >
                        1 CREDITO = 1 QR
                    </Heading>
                    <Image
                        src={HandWithCredits}
                        alt={"Créditos Tikzet"}
                        w={["full", "250px"]}
                    />
                </VStack>
                <VStack
                    p={[0, 4]}
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={1}
                    flex={1}
                    mt="auto"
                >
                    <Text
                        color="gray.400"
                        fontSize={["xs", "sm"]}
                        textDecor="line-through"
                    >
                        ARS$ {normalPrice}
                    </Text>
                    <Heading
                        fontWeight="normal"
                        color="primary"
                        fontSize={["2xl", "3xl"]}
                    >
                        <Heading
                            as="span"
                            fontSize="inherit"
                            color="inherit"
                            fontWeight="500"
                        >
                            ARS$ {cheapestPrice}
                        </Heading>
                        /crédito
                    </Heading>
                    <Text pt={3} color="gray.300" fontSize={["sm", "md"]}>
                        Lo ÚNICO que te vamos a cobrar es 1 crédito por cada QR
                        que envies.
                    </Text>
                </VStack>
            </Flex>
        </HStack>
    );
};

export default QrPrice;
