import { Flex, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import MercadoPagoIcon from "./assets/mercado_pago.webp";

const MercadoPago = () => {
    return (
        <HStack alignItems="flex-start" spacing={[0, 3]} w="full">
            <Text
                fontFamily="landingBody"
                transform={"auto"}
                rotate={-180}
                css={{
                    writingMode: "vertical-rl",
                }}
                color="gray.400"
                w={["10%", "auto"]}
            >
                COBRA AL INSTANTE
            </Text>
            <Flex
                w="full"
                flexDir={["column", "row"]}
                alignItems="flex-start"
                gap={[8, 3]}
            >
                <VStack alignItems="flex-start" spacing={3}>
                    <Heading
                        fontFamily="landingHeading"
                        fontSize={["35px", "3xl"]}
                        fontWeight="500"
                        lineHeight=".9"
                    >
                        COBRO DIRECTO
                    </Heading>
                    <Image
                        src={MercadoPagoIcon}
                        alt={"MercadoPago"}
                        w={["full", "250px"]}
                    />
                </VStack>
                <VStack
                    p={[0, 4]}
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={1}
                    flex={1}
                    mt="auto"
                >
                    <Heading fontWeight="normal" fontSize={["2xl", "3xl"]}>
                        Vinculación directa con Mercado Pago
                    </Heading>
                    <Text pt={3} color="gray.300" fontSize={["sm", "md"]}>
                        Podés vincular tu cuenta con un solo click al
                        registrarte y crear tu evento. De esta manera, cada vez
                        que vendas una entrada, el dinero se acredita
                        instantáneamente en tu cuenta.
                    </Text>
                </VStack>
            </Flex>
        </HStack>
    );
};

export default MercadoPago;
