import { Button, Icon, Link } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface Props {
    label: string;
    icon: IconType | React.ComponentType;
    to?: string;
    isDisabled?: boolean;
}

const SocialMediaButton = ({ label, icon, to, isDisabled }: Props) => {
    return (
        <Button
            bgColor="white"
            color="black"
            leftIcon={<Icon fontSize="xl" as={icon} />}
            iconSpacing={3}
            rounded={"full"}
            justifyContent="center"
            px={10}
            as={Link}
            textDecoration="none !important"
            href={to || "#"}
            isDisabled={isDisabled}
            w="full"
        >
            {label}
        </Button>
    );
};

export default SocialMediaButton;
