import { Button, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const TitleSection = () => {
    return (
        <VStack
            w={{ base: "full", md: "fit-content" }}
            spacing={{ base: 4, xl: 8 }}
            alignItems="flex-start"
        >
            <VStack w="full" alignItems="flex-start" spacing={3}>
                <Heading
                    variant="landingHeading"
                    fontSize={{
                        base: "3.6rem",
                        md: "2.6rem",
                        xl: "6xl",
                        "2xl": "7xl",
                    }}
                >
                    EL MEJOR <br />
                    SISTEMA DE <br />
                    <Heading
                        as="span"
                        bgGradient="linear(to-r, primary, #F6E9FF)"
                        fontSize="inherit"
                        bgClip="text"
                        variant="landingHeading"
                    >
                        INGRESOS QR
                    </Heading>
                </Heading>
                <Text
                    color="gray.400"
                    fontSize={{
                        base: "sm",
                        xl: "md",
                    }}
                    maxW={{ base: "full", md: "25vw", "2xl": "20vw" }}
                >
                    No compres sistemas costosos y complicados.{" "}
                    {import.meta.env.VITE_COMPANY_NAME} es la solución más
                    potente y fácil de usar del mercado.
                </Text>
            </VStack>
            <Button
                as={RouterLink}
                to={"/organizations/new?from=landing"}
                variant={"striking"}
                size={{ base: "sm", xl: "md" }}
            >
                Crear cuenta de organizador
            </Button>
            <Text
                color="gray.400"
                fontSize={{
                    base: "sm",
                    xl: "md",
                }}
            >
                ¿Tenés alguna consulta?{" "}
                <Link
                    color="primary"
                    href={`https://wa.me/${
                        import.meta.env.VITE_COMPANY_WHATSAPP
                    }`}
                    target="_blank"
                >
                    Contactanos.
                </Link>
            </Text>
        </VStack>
    );
};

export default TitleSection;
