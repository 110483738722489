import Background from "@assets/images/prices_credits.webp";
import { Image } from "@chakra-ui/react";

const CreditsImage = () => {
    return (
        <Image
            src={Background}
            h={{ base: "auto", md: "auto" }}
            w={{ base: "full", md: "auto" }}
            maxH={{ base: "auto", md: "40rem" }}
            maxW="100%"
            overflow="hidden"
        />
    );
};

export default CreditsImage;
