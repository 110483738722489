import {
    Button,
    Center,
    Heading,
    Link,
    Show,
    Text,
    VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const FreeCreditsSection = () => {
    return (
        <Center w="full" my={["3rem", "9rem"]}>
            <VStack
                w="full"
                spacing={{ base: 4, xl: 8 }}
                alignItems="flex-start"
            >
                <VStack w="full" alignItems="flex-start" spacing={3}>
                    <Heading
                        variant="landingHeading"
                        fontSize={{
                            base: "3rem",
                            md: "2.6rem",
                            xl: "3.5rem",
                            "2xl": "7xl",
                        }}
                    >
                        PROBA EL SISTEMA <br />
                        <Heading
                            as="span"
                            bgGradient="linear(to-r, primary, #F6E9FF)"
                            fontSize="inherit"
                            bgClip="text"
                            variant="landingHeading"
                        >
                            100% GRATIS
                        </Heading>
                    </Heading>
                    <Text
                        color="gray.400"
                        fontSize={{
                            base: "sm",
                            xl: "md",
                        }}
                    >
                        No es necesario que realices ninguna compra aún. Podes
                        registrarte y probar{" "}
                        <Show above="md">
                            <br />
                        </Show>{" "}
                        el sistema haciendo clic abajo.
                    </Text>
                </VStack>
                <Button
                    as={RouterLink}
                    to={"/organizations/new?from=landing"}
                    variant={"striking"}
                    size={{ base: "sm", xl: "md" }}
                >
                    Crear cuenta de organizador
                </Button>
                <Text
                    color="gray.400"
                    fontSize={{
                        base: "sm",
                        xl: "md",
                    }}
                >
                    ¿Tenés alguna consulta?{" "}
                    <Link
                        color="primary"
                        href={`https://wa.me/${
                            import.meta.env.VITE_COMPANY_WHATSAPP
                        }`}
                        target="_blank"
                    >
                        Contactanos.
                    </Link>
                </Text>
            </VStack>
        </Center>
    );
};

export default FreeCreditsSection;
