import { VStack } from "@chakra-ui/react";
import BoxedPresentationItem from "@components/BoxedPresentationItem/BoxedPresentationItem";
import LandingTitle from "@components/LandingTitle/LandingTitle";
import PhoneWithEvent from "./assets/phone_with_event.webp";
import PrintedTickets from "./assets/printed_tickets.webp";
import SendTickets from "./assets/send_tickets.webp";

const Services = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <LandingTitle title="NUESTROS SERVICIOS" />
            <BoxedPresentationItem
                title="Sistema de ventas: vendé más con una página web de tu evento"
                description="Es más probable que el cliente compre si puede hacerlo en un par de clics. Aprovechá tus redes y vendé más."
                subtitle="VENTA ONLINE"
                image={PhoneWithEvent}
                cta={{
                    label: "Ver más información",
                    href: "/online-sales",
                }}
            />
            <BoxedPresentationItem
                title="Sistema QR sin cobros: enviá entradas personalizadas por WhatsApp o Correo"
                description="No es necesario que vendas online. Podes usar el sistema solo para gestionar ingresos. Ahorrate los costos logísticos de las entradas físicas."
                subtitle="SISTEMA DE INGRESOS"
                image={SendTickets}
                cta={{
                    label: "Ver más información",
                    href: "/without-online-sales",
                }}
            />
            <BoxedPresentationItem
                title="QRs para entradas impresas: detectá entradas falsas"
                description="Generamos los QRs para que puedas enviarlos a la gráfica de tu elección. Si querés realizar un pedido de este tipo, contactanos."
                subtitle="ENTRADAS IMPRESAS"
                image={PrintedTickets}
                cta={{
                    label: "Contactar",
                    href: `https://wa.me/${
                        import.meta.env.VITE_COMPANY_WHATSAPP
                    }`,
                }}
            />
        </VStack>
    );
};

export default Services;
