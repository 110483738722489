import { Image } from "@chakra-ui/react";
import PhoneWithWhatsApp from "./assets/phone_with_whatsapp.webp";

const WhatsAppImage = () => {
    return (
        <Image
            src={PhoneWithWhatsApp}
            h={{ base: "auto", md: "auto" }}
            w={{ base: "full", md: "auto" }}
            maxH={{ base: "auto", md: "40rem" }}
            maxW="100%"
            overflow="hidden"
        />
    );
};

export default WhatsAppImage;
