import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { initializeSentry } from "./config/sentry";
import { theme } from "./config/theme";
import { AuthContextProvider } from "./contexts/AuthContext";
import { AuthenticatedAxiosProvider } from "./contexts/AuthenticatedAxiosContext";
import { CurrentOrganizationProvider } from "./contexts/CurrentOrganizationContext";

initializeSentry();

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const queryClient = new QueryClient();
const root = createRoot(container);

// Users that are in the app while a new deployment happens are getting white screens
// due to the lazy loading of the new chunks. This event listener will reload the page
// when a preload error (chunk no longer exists) happens.
window.addEventListener("vite:preloadError", () => {
    window.location.reload();
});

root.render(
    <QueryClientProvider client={queryClient}>
        <ChakraProvider
            theme={theme}
            toastOptions={{
                defaultOptions: {
                    duration: 3000,
                    isClosable: true,
                    position: "top",
                },
            }}
        >
            <AuthContextProvider>
                <BrowserRouter>
                    <AuthenticatedAxiosProvider>
                        <CurrentOrganizationProvider>
                            <App />
                        </CurrentOrganizationProvider>
                    </AuthenticatedAxiosProvider>
                </BrowserRouter>
            </AuthContextProvider>
        </ChakraProvider>
    </QueryClientProvider>
);
