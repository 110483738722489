import { useGetEventsQuery } from "@api/useCases/events/queries/getEvents/useGetEventsQuery";
import { HStack, Skeleton, useBreakpointValue, VStack } from "@chakra-ui/react";
import LandingTitle from "@components/LandingTitle/LandingTitle";
import EventsCarousel from "./components/EventsCarousel/EventsCarousel";

const RecentEvents = () => {
    const { data: events, isSuccess, isLoading } = useGetEventsQuery();
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <VStack w="full" alignItems="flex-start" spacing={8}>
            <LandingTitle title="EVENTOS RECIENTES" />
            <HStack
                w="100vw"
                spacing={5}
                minW="fit-content"
                justifyContent="center"
                ms={{ base: -5, md: "-15vw", lg: "-20vw", "2xl": "-25vw" }}
            >
                {isSuccess && <EventsCarousel events={events} />}
                {isLoading &&
                    Array.from({ length: isMobile ? 1 : 10 }).map((_, i) => (
                        <Skeleton
                            boxSize={{ base: "65vw", md: "240px" }}
                            rounded="2xl"
                            key={i}
                        />
                    ))}
            </HStack>
        </VStack>
    );
};

export default RecentEvents;
