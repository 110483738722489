import { UseGetPremiumCreditsPriceQueryResponse } from "@api/useCases/premiumCredits/queries/getPremiumCreditsPrice/useGetPremiumCreditsPriceQuery";
import { VStack } from "@chakra-ui/react";
import LandingTitle from "@components/LandingTitle/LandingTitle";
import PacksItem from "./components/PacksItem/PacksItem";

const getDiscountPercentage = (price: number, unitPrice: number) =>
    ((unitPrice - price) / unitPrice) * 100;

interface Props {
    prices: UseGetPremiumCreditsPriceQueryResponse;
}

const Packs = ({ prices }: Props) => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={20}>
            <LandingTitle title="PACKS" />
            <PacksItem
                image={prices.image}
                title="A partir de 20 créditos"
                description="Compra mínima"
                price={prices.unitPrice}
                quantity={20}
            />
            {prices.bundles
                .sort((a, b) => b.unitPrice - a.unitPrice)
                .map((b) => (
                    <PacksItem
                        image={b.image}
                        description={`${getDiscountPercentage(
                            b.unitPrice,
                            prices.unitPrice
                        )}% OFF`}
                        title={`Pack de ${b.start.toLocaleString(
                            navigator.language
                        )} créditos`}
                        price={b.unitPrice}
                        quantity={b.start}
                        key={b.start}
                    />
                ))}
        </VStack>
    );
};

export default Packs;
