import { MdOutlineEmail } from "react-icons/md";
import SocialMediaButton from "./SocialMediaButton";

const EmailSignIn = () => {
    return (
        <SocialMediaButton
            icon={MdOutlineEmail}
            label="Continuar con Email"
            to={`/auth/email`}
        />
    );
};

export default EmailSignIn;
