import Background from "@assets/images/how_to_buy_credits.webp";
import {
    Flex,
    Heading,
    HStack,
    Image,
    Show,
    Text,
    VStack,
} from "@chakra-ui/react";
import LandingTitle from "@components/LandingTitle/LandingTitle";

const HowToBuyCredits = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={[16, 20]}>
            <LandingTitle title="¿COMO COMPRO CREDITOS?" />
            <HStack alignItems="flex-start" spacing={[0, 3]} w="full">
                <Text
                    fontFamily="landingBody"
                    transform={"auto"}
                    rotate={-180}
                    css={{
                        writingMode: "vertical-rl",
                    }}
                    color="gray.400"
                    w={["10%", "auto"]}
                >
                    COMPRA DE CREDITOS
                </Text>
                <Flex
                    w="full"
                    flexDir={["column", "row"]}
                    alignItems="flex-start"
                    gap={[8, 5]}
                >
                    <Image
                        src={Background}
                        alt={"¿Cómo compro créditos?"}
                        w={["full", "250px"]}
                    />
                    <VStack
                        px={[0, 4]}
                        alignItems="flex-start"
                        justifyContent="center"
                        spacing={4}
                        flex={1}
                        mt="auto"
                    >
                        <Heading fontWeight="500" fontSize={["2xl", "3xl"]}>
                            Compra de créditos
                        </Heading>
                        <Text color="gray.300" fontSize={["sm", "md"]}>
                            Una vez que te registres, podes comprar los{" "}
                            <Show above="md">
                                <br />
                            </Show>
                            créditos desde el menú de Tikzet.
                        </Text>
                    </VStack>
                </Flex>
            </HStack>
        </VStack>
    );
};

export default HowToBuyCredits;
