import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface Props {
    children: ReactNode;
}

const LandingHeroWrapper = ({ children }: Props) => {
    return (
        <Flex
            justifyContent="center"
            alignItems={["flex-start", "center"]}
            w="full"
            h="100vh"
            minH="fit-content"
            pt={{ base: 5, md: 0 }}
        >
            <Flex
                w={"full"}
                minH="fit-content"
                flexDir={{ base: "column-reverse", md: "row" }}
                justifyContent={{ base: "flex-start", md: "space-between" }}
                alignItems="flex-end"
                gap={{ base: 6, md: "5rem" }}
            >
                {children}
            </Flex>
        </Flex>
    );
};

export default LandingHeroWrapper;
