import LandingHeroWrapper from "@components/LandingHeroWrapper/LandingHeroWrapper";
import EventImage from "./components/EventImage/EventImage";
import TitleSection from "./components/TitleSection/TitleSection";

const Hero = () => {
    return (
        <LandingHeroWrapper>
            <TitleSection />
            <EventImage />
        </LandingHeroWrapper>
    );
};

export default Hero;
