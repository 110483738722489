import { useEffect } from "react";

const useGoogleTagManager = () => {
    useEffect(() => {
        if (!import.meta.env.PROD) return;

        const script = document.createElement("script");
        script.src =
            "https://www.googletagmanager.com/gtag/js?id=AW-11345146682";
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement("script");
        script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'AW-11345146682');
    `;
        document.head.appendChild(script2);
    }, []);
};

export default useGoogleTagManager;
