import { useGetPremiumCreditsPriceQuery } from "@api/useCases/premiumCredits/queries/getPremiumCreditsPrice/useGetPremiumCreditsPriceQuery";
import { Box, Center, Heading, VStack } from "@chakra-ui/react";
import Footer from "@components/Footer/Footer";
import FreeCreditsSection from "@components/FreeCreditsSection/FreeCreditsSection";
import LoadingOverlay from "@components/LoadingOverlay";
import { useNavbarHeight } from "@hooks/useNavbarHeight";
import Hero from "./components/Hero/Hero";
import HowToBuyCredits from "./components/HowToBuyCredits/HowToBuyCredits";
import Packs from "./components/Packs/Packs";

const PricesPage = () => {
    const navbarHeight = useNavbarHeight();

    const {
        data: prices,
        isLoading,
        isSuccess,
    } = useGetPremiumCreditsPriceQuery();

    if (isLoading) return <LoadingOverlay />;

    if (!isSuccess)
        return (
            <Center h="100vh" p={5}>
                <Heading>
                    Hubo un error, por favor, intenta de nuevo más tarde.
                </Heading>
            </Center>
        );

    return (
        <VStack
            w="full"
            spacing="8rem"
            pt={{ base: navbarHeight, md: 0 }}
            px={{ base: 5, md: "15vw", lg: "19vw", "2xl": "25vw" }}
        >
            <Hero
                cheapestPrice={
                    prices.bundles.sort((a, b) => a.unitPrice - b.unitPrice)[0]
                        .unitPrice
                }
                normalPrice={prices.unitPrice}
            />
            <Packs prices={prices} />
            <HowToBuyCredits />
            <FreeCreditsSection />
            <Box w="100vw">
                <Footer />
            </Box>
        </VStack>
    );
};

export default PricesPage;
