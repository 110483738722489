export const MAX_TRUNCATE_LENGTH = 20;

export const truncateAndAddEllipsis = (
    str: string,
    maxLength: number = MAX_TRUNCATE_LENGTH
) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
    }
    return str;
};

export const appendQueryParam = (
    url: string,
    paramName: string,
    paramValue: string
) => {
    const separator = url.includes("?") ? "&" : "?";
    return `${url}${separator}${paramName}=${paramValue}`;
};
