import { useRoutes } from "react-router-dom";
import { routeItems } from "./config/routing";
import useFacebookPixel from "./hooks/useFacebookPixel";
import useGoogleTagManager from "./hooks/useGoogleTagManager";

const App = () => {
    useGoogleTagManager();
    useFacebookPixel();

    return useRoutes(routeItems);
};

export default App;
