import { Center, GridItem, Show, SimpleGrid, VStack } from "@chakra-ui/react";
import LandingTitle from "@components/LandingTitle/LandingTitle";
import EventCreation from "./assets/event_creation.webp";
import OrgCreation from "./assets/org_creation.webp";
import Step1 from "./assets/step1.svg";
import Step2 from "./assets/step2.svg";
import Step3 from "./assets/step3.svg";
import TicketsManagement from "./assets/tickets_management.webp";
import CardContent from "./components/CardContent/CardContent";
import CreateOrgButton from "./components/CreateOrgButton/CreateOrgButton";
import StepItem from "./components/StepItem/StepItem";

const HowToSection = () => {
    return (
        <VStack w="full" alignItems="flex-start" spacing={[12, 20]}>
            <LandingTitle title="¿COMO EMPIEZO A USAR EL SISTEMA?" />
            <VStack spacing={10} w="full">
                <SimpleGrid columns={[1, 3]} spacing={[6, 10]} w="full">
                    <GridItem colSpan={1}>
                        <StepItem
                            stepImage={Step1}
                            title="REGISTRATE GRATIS"
                            description="Te pediremos un nombre y una foto para el perfil de tu productora de eventos."
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <StepItem
                            stepImage={Step2}
                            title="CREA TU EVENTO"
                            description="Te pediremos algunos datos importantes. ¡Es muy fácil!"
                        />
                    </GridItem>
                    <GridItem colSpan={1}>
                        <StepItem
                            stepImage={Step3}
                            title="GESTIONA INGRESOS"
                            description="Ya podés enviar entradas, vender online y escanear QRs."
                        />
                    </GridItem>
                </SimpleGrid>
                <Show above="md">
                    <SimpleGrid columns={[1, 3]} spacing={10} w="full">
                        <GridItem colSpan={1}>
                            <CardContent
                                description="Te pediremos un nombre y una foto para el perfil de tu productora de eventos."
                                image={EventCreation}
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <CardContent
                                description="Te pediremos algunos datos importantes. ¡Es muy fácil!"
                                image={OrgCreation}
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <CardContent
                                description="Ya podés enviar entradas, vender online y escanear QRs."
                                image={TicketsManagement}
                            />
                        </GridItem>
                    </SimpleGrid>
                </Show>
            </VStack>
            <Center w={["fit-content", "full"]}>
                <CreateOrgButton />
            </Center>
        </VStack>
    );
};

export default HowToSection;
